<i18n>
{
  "de": {
    "pageTitle": "Benutzer bearbeiten",
    "firstNameLabel": "Vorname",
    "lastNameLabel": "Nachname",
    "companyNameLabel": "Firma",
    "emailLabel": "E-Mail",
    "errorEmailInvalid": "Ungültige E-Mail-Adresse",
    "submitButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen"
  }
}
</i18n>

<template>
  <MainLayout class="admin-edit-user-page" :ready="allUsersReady">
    <template #default>
      <p v-if="error">{{ error }}</p>
      <h1>{{ $t('pageTitle') }}</h1>
      <form>
        <div class="form-item">
          <label for="user-first-name-field">{{ $t('firstNameLabel') }}</label>
          <input id="user-first-name-field" v-model="firstName" type="text" />
        </div>
        <div class="form-item">
          <label for="user-last-name-field">{{ $t('lastNameLabel') }}</label>
          <input id="user-last-name-field" v-model="lastName" type="text" />
        </div>
        <div class="form-item">
          <label for="user-company-name-field">{{ $t('companyNameLabel') }}</label>
          <input id="user-company-name-field" v-model="companyName" type="text" />
        </div>
        <div class="form-item">
          <label for="user-email-field">{{ $t('emailLabel') }}</label>
          <div>
            <input id="user-email-field" v-model="email" type="email" :class="{ 'invalid-email': !isEmailValid }" />
            <div v-if="!isEmailValid" class="invalid-message">
              <span>{{ $t('errorEmailInvalid') }}</span>
            </div>
          </div>
        </div>
      </form>
      <ButtonWrapper>
        <button type="button" class="button" :disabled="!isSubmitButtonEnabled" @click="onSubmit">
          {{ $t('submitButtonLabel') }}
        </button>
        <button type="button" class="button" @click="onCancel">{{ $t('cancelButtonLabel') }}</button>
      </ButtonWrapper>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { isValidEmail } from '@/services/util.js'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin.vue'
import AdminUserMixin from '@/pages/vuex-mixins/AdminUserMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  name: 'adminEditUser',

  mixins: [
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
    AdminUserMixin, // Provides: user
  ],

  components: {
    ButtonWrapper,
    MainLayout,
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      error: null,
      isEmailValid: false,
    }
  },

  computed: {
    isSubmitButtonEnabled() {
      return Boolean(this.firstName && this.lastName && this.isEmailValid)
    },
  },

  watch: {
    user() {
      this.userChanged()
    },

    email() {
      this.isEmailValid = isValidEmail(this.email)
    },
  },

  created() {
    this.userChanged()
  },

  methods: {
    ...mapActions({
      updateUser: 'users/updateUser',
    }),

    userChanged() {
      if (this.user) {
        this.firstName = this.user.first_name
        this.lastName = this.user.last_name
        this.companyName = this.user.company_name
        this.email = this.user.email
      }
    },

    async onSubmit() {
      try {
        this.error = null
        await this.updateUser({
          id: this.user.id,
          first_name: this.firstName,
          last_name: this.lastName,
          company_name: this.companyName,
          email: this.email,
        })
        this.$router.push({ name: 'adminUser', params: { user_id: this.user.id } })
      } catch (error) {
        this.error = error
      }
    },

    onCancel() {
      this.$router.push({ name: 'adminUser', params: { user_id: this.user.id } })
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.admin-edit-user-page {
  & form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
    width: 400px;

    & .form-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #fff;
      margin-bottom: 0px;
      transition: margin 0.2s ease-in;

      & label {
        flex: none;
        width: 120px;
        display: block;
        font-weight: 500;
        line-height: 28px;
      }

      & > div {
        flex: 1;
      }

      &:has(.invalid-message) {
        margin-bottom: 20px;

        & .invalid-message span {
          opacity: 1;
        }
      }
    }
  }

  & .invalid-message {
    position: relative;
    color: #e11;
    font-size: 15px;
    line-height: 20px;

    & span {
      position: absolute;
      transition: opacity 0.3s ease-in;
      opacity: 0;
    }
  }

  & input {
    &.invalid-email {
      border-color: #e11 !important;

      &:focus {
        border-color: #e11 !important;
        outline: 3px solid #fdd !important;
      }
    }
  }
}
</style>
